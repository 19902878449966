import React from 'react';
import { useLocation } from 'react-router-dom';
//import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
//import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function ResultatsSeries() {
 
	  const location = useLocation();
	  const resultats = location.state?.resultats;
	  const searchTerm = location.state?.searchTerm;

	  App();


	  console.log("Type de resultats:", typeof resultats);
  	  console.log("Valeur de resultats:", resultats);
  	  console.log("Recherche pour:", searchTerm);

  	  const resultatsArray = Object.values(resultats);


  	

  	const renderContent = () => {
	  return (
	    <div className="Resultats">
	    <Menu />
	      {/*<a href="/serie" className="btn-back">←</a>*/}
	      <h1 className="ResultatsdeRecherche">Résultats pour <strong><u>{searchTerm}</u></strong></h1>
	      {resultats ? (
	        resultatsArray.map((resultat) => (
	         <a href={`/serie/${resultat.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
	          <div className="Liste" key={resultat.serie_id}>
	          	<div className="col_1_3">
		          <LazyLoadImage
		            alt={resultat.titre}
		            height={'220'}
		            src={resultat.affiche_moyen} // use normal <img> attributes as props
		            width={'165'} 
		            className="lazy-load-image" />
		          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
		        </div>
		        <div className="col_2_3">
		          <h2 className="pbottom8 m">{resultat.titre}</h2>
		          <p>{resultat.date_sortie}<br/>{resultat.plateforme}</p>
		        </div>
	          </div>
	         </a>

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}
	    </div>
	  );
	}

	 return (
    <div className="App">
      
        <div>
         {renderContent()}
        </div>
      
    </div>
  );
	
}

export default ResultatsSeries;
