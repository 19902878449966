import React, { useState, useEffect } from 'react';
/*import { useLocation } from 'react-router-dom';*/

function App() {
  const [darkMode, setDarkMode] = useState(
    window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
  );

  useEffect(() => {
    // Fonction pour gérer le changement de mode
    const setThemeMode = () => {
      const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
      document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
    };

    // Appliquer le mode initial
    setThemeMode();

    // Écouter les changements de préférence du système
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', setThemeMode);

    // Nettoyer l'écouteur d'événement lors du démontage du composant
    return () => {
      mediaQuery.removeEventListener('change', setThemeMode);
    };
  }, []);


  /*const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const mode = queryParams.get('mode');

  document.documentElement.setAttribute('data-theme', mode);*/


  
}

export default App;