import React, { useState } from 'react';

const Menu = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

 
  
  return (
    <div className="menucache">
      <div className="burger-menu" onClick={toggleMenu}>
        ☰
      </div>

      <div className={`menu-list ${menuOpen ? 'show' : ''}`}>
      	<div className="menu-item"><a href={`/serie?time=${new Date().getTime()}`}>Séries</a></div>
        <div className="menu-item"><a href={`/personne?time=${new Date().getTime()}`}>People</a></div>
        <div className="menu-item"><a href={`/voxographie?time=${new Date().getTime()}`}>Voxographie</a></div>
      </div>
    </div>
  );
};

export default Menu;