import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import SearchBar from './SearchBar';
import Menu from './Menu';
import App from './App';

function Series() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();

  //console.log("ID:", id);
  const navigate = useNavigate();
  const handleSearch = (term) => {
    console.log(term);
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=serie&q=${term}&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
        navigate('/serie/resultats', { state: { resultats: jsonData, searchTerm: term } });
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });

    
  };

  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=serie&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, []); // Mettre à jour lorsque id change


 

  console.log("DATAS:", data);



  let series_vod // series_vod = series VOD à venir
  if (data.series_vod) {
    series_vod = data.series_vod;
  } else {
    series_vod = [];
  }

  let series_prochainement // series_prochainement = series à venir
  if (data.series_prochainement) {
    series_prochainement = data.series_prochainement;
  } else {
    series_prochainement = [];
  }

  let series_recentes // series_recentes = series récentes
  if (data.series_recentes) {
    series_recentes = data.series_recentes;
  } else {
    series_recentes = [];
  }

  let top_series // top_series = series à mettre en avant
  if (data.top_series) {
    top_series = data.top_series;
  } else {
    top_series = [];
  }

  let actus_series // actus_series = dernières actualités séries
  if (data.actus_series) {
    actus_series = data.actus_series;
  } else {
    actus_series = [];
  }

  let series_videos // series_videos = dernières vidéos séries
  if (data.series_videos) {
    series_videos = data.series_videos;
  } else {
    series_videos = [];
  }


  /*const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };*/

  const GUTTER_SIZE = 20;

  const RowSerieSaison = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://serie/${image.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.serie_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st cutted">{image.titre}</h3>
          <h4 className="st"><strong>{image.plateforme}</strong> {image.date_sortie}<br/>saison {image.saison}</h4>
        </a>
      </div>
    );
  };

  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.actu_id}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.actu_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st">{image.datepub}</h4>
        </a>
      </div>
    );
  };

  const RowSerieBA= ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://serie/${image.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.serie_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st cutted">{image.titre}</h3>
          <h4 className="st">{image.datepub}</h4>
        </a>
      </div>
    );
  };




  const renderContent = () => {
    
      return <div className="Series">

        <Menu />

        
       
        <h1>Séries</h1>
        <p>Series TV, VOD et streaming.<br/>Retrouvez l'actualité des séries TV, VOD et l'agenda des sorties.</p>
        
        <SearchBar onSearch={handleSearch} />
        
        <h2 className="st">Sorties VOD</h2>
        {series_vod && series_vod.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series_vod.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_vod} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}

        <h2 className="st">Séries à venir</h2>
        {series_prochainement && series_prochainement.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series_prochainement.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_prochainement} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}

        <h2 className="st">Séries récentes</h2>
        {series_recentes && series_recentes.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series_recentes.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_recentes} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}


        <h2 className="st">Actualités des Séries</h2>
        {actus_series && actus_series.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={actus_series.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={actus_series} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun article.</div>
        )}


        <h2 className="st">Bandes Annonces Séries</h2>
        {series_videos && series_videos.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={series_videos.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_videos} // Données à passer à chaque élément
          >
            {RowSerieBA}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune video.</div>
        )}



        <h2 className="st">TOP Séries</h2>
        {top_series && top_series.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={top_series.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={top_series} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}



      </div>;
    
  };
  
  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Series;
