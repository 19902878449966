import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function Serie() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); // Récupérer le paramètre id depuis l'URL
  const [playVideo, setPlayVideo] = useState(false);

  App();
  
  console.log("ID:", id);
 
  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=serie&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, [id]); // Mettre à jour lorsque id change


 


  console.log("DATAS:", data);

  
  const { personnes } = data; // Récupérer le champ personnes de l'objet data
  const { genre } = data; // Récupérer le champ genre de l'objet data
  const { plateformesvod } = data; // Récupérer le champ plateformesvod de l'objet data


  let article; // articles de la série
  if (data.article) {
    article = data.article;
  } else {
    article = [];
  }

 
  /*const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    centerMode: false,
    slidesToShow: 1.2,
    swipeToSlide: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2
          }
        }
    ]
  };

  const settings3 = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    centerMode: false,
    className: 'Casting',
    slidesToShow: 3.3,
    initialSlide: 0,
    swipeToSlide: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 6.3
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4.3
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3.3
          }
        }
    ]
  };*/

  const GUTTER_SIZE = 20;

  const RowPersonne = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://personne/${image.aid}`} rel="noopener noreferrer">
          <img src={image.media_moyen} alt={`${image.aid}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st text-center">{image.nom}</h3>
          <h4 className="st text-center">{image.role}</h4>
        </a>
      </div>
    );
  };

  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.article_id}`} rel="noopener noreferrer">
          <img src={image.media_url} alt={`${image.article_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.article_titre}</h3>
          <h4 className="st">{image.article_date}</h4>
        </a>
      </div>
    );
  };

  
  const openInExternalBrowser = (url) => {
    return (event) => {
      event.preventDefault();
      window.open(url, '_blank', 'noopener,noreferrer');
    };
  };

  const Cover = () => {
    if (data.video_jpg) {
      return <div className="cover" style={{ position: 'relative' }}>
          {playVideo ? (
            <video controls style={{ width: '100%' }} autoPlay>
              <source src={data.video_mp4} type="video/mp4" />
              Votre navigateur ne prend pas en charge la vidéo.
            </video>
          ) : (
            <>
            <img
              src={data.video_jpg}
              alt={data.serie_titre}
              onClick={() => setPlayVideo(true)}
              style={{ cursor: 'pointer', width: '100%'  }} 
              className="lazy-load-image" 
            />
            <div
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.6)',
                borderRadius: '50%',
                padding: '10px',
              }}
              onClick={() => setPlayVideo(true)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="60"
                height="60"
                viewBox="0 0 24 24"
                fill="white"
              >
                <path d="M8 5v14l11-7z" fill="white"/>
              </svg>
            </div>
            </>
          )}
        </div>;
    } else {
      return <div className="cover">
            <img
              src={data.affiche_moyen}
              alt={data.serie_titre} 
              className="lazy-load-image" 
            />
          </div>
    }
  };


  
  const renderContent = () => {
    
      return <div className="Serie">

        <Menu />

        <div className="cover-container">{Cover()}</div>

        <h1>{data.serie_titre}</h1>
        <p><span className="actuInfos">{data.chaine}</span> <span className="actuInfos">{data.annee_debut}</span> <span className="actuInfos">{data.pays}</span></p>
        <p>Saison {data.saison_nb} : <strong>{data.date_pub}</strong></p>

        {genre && genre.length > 0 ? (
        <p>
          {genre.map((bloc, index) => (
            <span key={index} className="actuInfos2">{bloc.nom}</span>
          ))}
        </p>
          ) : (
          <p className="noData"></p>
        )}

  
        <h2 className="st">Synopsis</h2>
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.synopsis) }} />

       

        <h2 className="st">Où regarder</h2>
        {plateformesvod && plateformesvod.length > 0 ? (
          <p>
          {plateformesvod.map((plateforme, index) => (
            <a
              className="noborder"
              href={plateforme.url}
              onClick={openInExternalBrowser(plateforme.url)}
              key={index}
            >
              <img className="logo_vod" src={plateforme.img_url} alt={plateforme.provider} />
            </a>
          ))}
          </p>
          ) : (
          <div className="aucuneinfo">Aucune info.</div>
        )}

       
        <h2 className="st">Casting</h2>
        {personnes && personnes.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={personnes.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={personnes} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}


        <h2 className="st">Actualités de {data.serie_titre}</h2>
        {article && article.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={article.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={article} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun article.</div>
        )}


      </div>;
    
  };

  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Serie;
