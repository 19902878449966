import React from 'react';
//import { useParams } from 'react-router-dom';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
//import { RotatingLines } from 'react-loader-spinner';


function Home() {
  //const [loading, setLoading] = useState(true);
  
  return (
    <div className="App">
      <a href="cine974://">Retourner sur l'application</a>
    </div>
  );
}

export default Home;
