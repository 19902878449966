import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import SearchBar from './SearchBar';
import Menu from './Menu';
import App from './App';

function Personnes() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();

  //console.log("ID:", id);

  const navigate = useNavigate();
  const handleSearch = (term) => {
    console.log(term);
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=personne&q=${term}&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
        navigate('/personne/resultats', { state: { resultats: jsonData, searchTerm: term } });
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });

    
  };

  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=personne&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, []); // Mettre à jour lorsque id change


 


  console.log("DATAS:", data);



  let a_laffiche // a_laffiche
  if (data.a_laffiche) {
    a_laffiche = data.a_laffiche;
  } else {
    a_laffiche = [];
  }

  let top_acteurs // top_acteurs
  if (data.top_acteurs) {
    top_acteurs = data.top_acteurs;
  } else {
    top_acteurs = [];
  }

  let actrices_sexy // actrices_sexy
  if (data.actrices_sexy) {
    actrices_sexy = data.actrices_sexy;
  } else {
    actrices_sexy = [];
  }

  let acteurs_sexy // acteurs_sexy
  if (data.acteurs_sexy) {
    acteurs_sexy = data.acteurs_sexy;
  } else {
    acteurs_sexy = [];
  }


  let top_acteurs_nbfilms // top_acteurs_nbfilms
  if (data.top_acteurs_nbfilms) {
    top_acteurs_nbfilms = data.top_acteurs_nbfilms;
  } else {
    top_acteurs_nbfilms = [];
  }

  let acteurs_modifs // dernieres_modifications
  if (data.acteurs_modifs) {
    acteurs_modifs = data.acteurs_modifs;
  } else {
    acteurs_modifs = [];
  }

  let acteurs_prenoms_populaires_femmes // prenoms populaires femmes
  if (data.acteurs_prenoms_populaires_femmes) {
    acteurs_prenoms_populaires_femmes = data.acteurs_prenoms_populaires_femmes;
  } else {
    acteurs_prenoms_populaires_femmes = [];
  }

  let acteurs_prenoms_populaires_hommes // prenoms populaires hommes
  if (data.acteurs_prenoms_populaires_hommes) {
    acteurs_prenoms_populaires_hommes = data.acteurs_prenoms_populaires_hommes;
  } else {
    acteurs_prenoms_populaires_hommes = [];
  }
  

  /*const settings3 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3.3,
    lazyLoad: true,
    centerMode: false,
    className: 'Casting',
  	swipeToSlide: true
  };*/

  const GUTTER_SIZE = 20;

  const RowPersonne = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://personne/${image.aid}`} rel="noopener noreferrer">
          <img src={image.media_moyen} alt={`${image.aid}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st text-center">{image.nom}</h3>
          <h4 className="st text-center">{image.note}</h4>
          <h4 className="st text-center"><em>{image.role}</em></h4>
        </a>
        
      </div>
    );
  };

  const RowPersonne3 = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://personne/${image.id}`} rel="noopener noreferrer">
          <img src={image.media_moyen} alt={`${image.id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st text-center">{image.nom}</h3>
          <h4 className="st text-center"><em>{image.titre}</em></h4>
        </a>
        
      </div>
    );
  };

  const RowPersonne2 = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://personne/${image.aid}`} rel="noopener noreferrer">
          <img src={image.media_moyen} alt={`${image.aid}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st text-center">{image.nom}</h3>
          <h4 className="st text-center">{image.note} films</h4>
        </a>
        
      </div>
    );
  };

 


  const renderContent = () => {
    
      return <div className="Personnes">

      	<Menu />
       
        <h1>People</h1>
        <p>Retrouvez les acteurs à la Une, les TOP acteurs, les réalisateurs, les actrices Sexy...</p>
        
        <SearchBar onSearch={handleSearch} />
        
        <h2 className="st">Acteurs à l'affiche <a href="cine974://cine+++/10000014" className="voirplus">Voir plus</a></h2>
        {a_laffiche && a_laffiche.length > 0 ? (
          <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={a_laffiche.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={a_laffiche} // Données à passer à chaque élément
          >
            {RowPersonne3}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}


        <h2 className="st">TOP Acteurs</h2>
        {top_acteurs && top_acteurs.length > 0 ? (
          <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={top_acteurs.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={top_acteurs} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}


        <h2 className="st">Actrices Sexy <a href="cine974://cine+++/10000012" className="voirplus">Voir plus</a></h2>
        {actrices_sexy && actrices_sexy.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={actrices_sexy.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={actrices_sexy} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}

        <h2 className="st">Acteurs Sexy <a href="cine974://cine+++/10000013" className="voirplus">Voir plus</a></h2>
        {acteurs_sexy && acteurs_sexy.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={acteurs_sexy.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={acteurs_sexy} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}

      


        <h2 className="st">Acteurs ayant joués dans le plus de films</h2>
        {top_acteurs_nbfilms && top_acteurs_nbfilms.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={top_acteurs_nbfilms.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={top_acteurs_nbfilms} // Données à passer à chaque élément
          >
            {RowPersonne2}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}


        <h2 className="st">Derniers ajouts et modifications</h2>
        {acteurs_modifs && acteurs_modifs.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={acteurs_modifs.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={acteurs_modifs} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune personne.</div>
        )}


        <h2 className="st">Prénoms populaires</h2>

        <p className="nomarginbottom"><span className="gender-female">♀</span> Prénoms les plus populaires chez les <span className="gender-female"><strong>femmes</strong></span>.</p>
        <div>
        {acteurs_prenoms_populaires_femmes && acteurs_prenoms_populaires_femmes.length > 0 ? (
        	acteurs_prenoms_populaires_femmes.map((item, index) => (
        		<>
        		<span key="{index}" className="linkListe2">{item.prenom}</span>
        		{index !== acteurs_prenoms_populaires_femmes.length - 1 && ','}
        		</>
        	))
          ) : (
          <span></span>
        )}
        </div>

        <p className="nomarginbottom"><span className="gender-male">♂</span> Prénoms les plus populaires chez les <span className="gender-male"><strong>hommes</strong></span>.</p>
        <div>
        {acteurs_prenoms_populaires_hommes && acteurs_prenoms_populaires_hommes.length > 0 ? (
        	acteurs_prenoms_populaires_hommes.map((item, index) => (
        		<>
        		<span key="{index}" className="linkListe2">{item.prenom}</span>
        		{index !== acteurs_prenoms_populaires_hommes.length - 1 && ','}
        		</>
        	))
          ) : (
          <span></span>
        )}
        </div>


      </div>;
    
  };
  
  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Personnes;
