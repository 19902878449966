import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';


function TopFilmsAnnee() {
	const [data, setData] = useState({});
  	const [loading, setLoading] = useState(true);
  	const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  	App();

  	
  	useEffect(() => {
	    // Charger les données depuis le fichier PHP
  		fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=topfilmsannee&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
  		.then((response) => response.json())
  		.then((jsonData) => {
  			setData(jsonData);
  			setLoading(false);
  		})
  		.catch((error) => {
  			console.error('Erreur de chargement:', error);
  			setLoading(false);
  		});
	  }, [id]); // Mettre à jour lorsque id change


  	
  
  
  	console.log("DATAS:", data);

	let films // films
	if (data.films) {
		films = data.films;
	} else {
		films = [];
	}



	console.log(films);

	const renderContent = () => {
	  return (
	    <div className="TopFilmsAnnee">

	      <Menu />
	      
	      <h1>Top Films Cine974 {id}</h1>
	      <p className="Liste"><a href="cine974://cine+++/10000010" className="btn-back">← retour au Top Films</a></p>
	      
	      {films ? (
	        films.map((resultat, index) => (
	         
	          <div className="Liste" key={index}>
	          	<div className="col_1_3">
	          	  <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer" className="noborder">
			          <LazyLoadImage
			            alt={resultat.titre}
			            height={'220'}
			            src={resultat.media_url} // use normal <img> attributes as props
			            width={'165'} 
			            className="lazy-load-image"
			             />
			          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
			      </a>
		        </div>
		        <div className="col_2_3">
		          <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer"><h2 className="pbottom0 m">{resultat.titre}</h2></a>
		          

		          <p className="nomargintop nomarginbottom"><em>de</em> 
			          {resultat.realisateurs.map((item, index) => (
		          	  <>
		              	<a className="linkListe3 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.realisateurs.length - 1 && ','}
		              </>
		            ))}
			        </p>

			        <p className="nomargintop"><em>avec</em> 
			          {resultat.casting.map((item, index) => (
		          	  <>
		              	<a className="linkListe3 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.casting.length - 1 && ','}
		              </>
		            ))} ...
			        </p>
			         
		          <p className="nomargintop"><span className="actuInfos">🏆 {index+1}</span><br/><span>★ {resultat.note} ({resultat.nbnote})</span></p>
		        </div>
	          </div>
	         

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}

	    

	      

	    </div>


	  );
	}

	return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
 
  );


}


export default TopFilmsAnnee;