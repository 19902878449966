import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function CineMarmay() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();

  

  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=cinemarmay&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, []); // Mettre à jour lorsque id change


 

  console.log("DATAS:", data);


  
  let films_ensalle // films_ensalle = films en salle
  if (data.films_ensalle) {
    films_ensalle = data.films_ensalle;
  } else {
    films_ensalle = [];
  }

  let films_prochainement // films_prochainement = films à venir
  if (data.films_prochainement) {
    films_prochainement = data.films_prochainement;
  } else {
    films_prochainement = [];
  }


  let sorties_vod // sorties_vod = Films SVOD à venir
  if (data.sorties_vod) {
    sorties_vod = data.sorties_vod;
  } else {
    sorties_vod = [];
  }

  let sorties_adecouvrir // sorties_adecouvrir = series à découvrir
  if (data.sorties_adecouvrir) {
    sorties_adecouvrir = data.sorties_adecouvrir;
  } else {
    sorties_adecouvrir = [];
  }


  let sorties_series // sorties_series = series VOD à venir
  if (data.sorties_series) {
    sorties_series = data.sorties_series;
  } else {
    sorties_series = [];
  }

  let series_adecouvrir // series_adecouvrir = series à découvrir
  if (data.series_adecouvrir) {
    series_adecouvrir = data.series_adecouvrir;
  } else {
    series_adecouvrir = [];
  }


  let evenements // evenements = prochains evenements marmay
  if (data.evenements) {
    evenements = data.evenements;
  } else {
    evenements = [];
  }

  let bandesannonces // bandesannonces = dernières vidéos films
  if (data.bandesannonces) {
    bandesannonces = data.bandesannonces;
  } else {
    bandesannonces = [];
  }

 

  /*const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };*/

  const GUTTER_SIZE = 20;

  const RowSerieSaison = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://serie/${image.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.serie_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st cutted">{image.titre}</h3>
          <h4 className="st"><strong>{image.plateforme}</strong> {image.date_sortie}<br/>saison {image.saison}</h4>
        </a>
      </div>
    );
  };

  const RowFilm = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://film/${image.film_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.film_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st cutted">{image.titre}</h3>
          <h4 className="st">{image.date_sortie}</h4>
        </a>
      </div>
    );
  };
  const RowFilmSVOD = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://film/${image.film_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.film_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st cutted">{image.titre}</h3>
          <h4 className="st"><strong>{image.plateforme}</strong> {image.date_sortie}<br/>{image.mention}</h4>
        </a>
      </div>
    );
  };

  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.actu_id}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.actu_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st">{image.datepub} à {image.lieu}</h4>
        </a>
      </div>
    );
  };

  const RowBA= ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://film/${image.film_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.film_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st">{image.datepub}</h4>
        </a>
      </div>
    );
  };




  const renderContent = () => {
    
      return <div className="Series">

        <Menu />

        
       
        <h1>Ciné Marmay 🎈</h1>
        <p>Ciné, Series TV, et SVoD<br/>Retrouvez le Programme Cinéma Marmay, les Prochaines Sorties des films pour enfants, les Bandes Annonces pour les enfants, une sélection de films à voir en SVOD sur Netflix, Disney+ etc. uniquement pour le jeune public, les Prochaines Évènements Cinéma à La Réunion pour les Marmay...</p>
        
       
        
        <h2 className="st">Films en salle</h2>
        {films_ensalle && films_ensalle.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={films_ensalle.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={films_ensalle} // Données à passer à chaque élément
          >
            {RowFilm}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun film.</div>
        )}

        <h2 className="st">Prochaines sorties Ciné</h2>
        {films_prochainement && films_prochainement.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={films_prochainement.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={films_prochainement} // Données à passer à chaque élément
          >
            {RowFilm}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun film.</div>
        )}

     


        <h2 className="st">Évènements Ciné Marmay</h2>
        {evenements && evenements.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={evenements.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={evenements} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun article.</div>
        )}

        


        <h2 className="st">Films SVoD Nouveautés</h2>
        {sorties_vod && sorties_vod.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={sorties_vod.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={sorties_vod} // Données à passer à chaque élément
          >
            {RowFilmSVOD}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun film.</div>
        )}

        <h2 className="st">Films SVoD à découvrir</h2>
        {sorties_adecouvrir && sorties_adecouvrir.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={sorties_adecouvrir.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={sorties_adecouvrir} // Données à passer à chaque élément
          >
            {RowFilmSVOD}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun film.</div>
        )}



        <h2 className="st">Bandes Annonces Films</h2>
        {bandesannonces && bandesannonces.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={bandesannonces.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={bandesannonces} // Données à passer à chaque élément
          >
            {RowBA}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune video.</div>
        )}




        <h2 className="st">Séries SVoD Nouveautés</h2>
        {sorties_series && sorties_series.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={sorties_series.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={sorties_series} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}

        <h2 className="st">Séries SVoD à découvrir</h2>
        {series_adecouvrir && series_adecouvrir.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series_adecouvrir.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_adecouvrir} // Données à passer à chaque élément
          >
            {RowSerieSaison}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}




      </div>;
    
  };
  
  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default CineMarmay;
