import React from 'react';
import { useLocation } from 'react-router-dom';
//import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
//import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function ResultatsRoles() {
 
	  const location = useLocation();
	  const resultats = location.state?.resultats;
	  const searchTerm = location.state?.searchTerm;

	  App();


	  console.log("Type de resultats:", typeof resultats);
  	  console.log("Valeur de resultats:", resultats);
  	  console.log("Recherche pour:", searchTerm);

  	  const resultatsArray = Object.values(resultats);


  	


  	const renderContent = () => {
	  return (
	    <div className="Resultats">
	    <Menu />

	      {/*<a href="/personne" className="btn-back">←</a>*/}
	      <h1>Résultats pour <strong><u>{searchTerm}</u></strong></h1>
	      <p className="Liste"><a href="cine974://cine+++/10000008" className="btn-back">← nouvelle recherche</a></p>
	      {resultats ? (
	        resultatsArray.map((resultat) => (
	         <a href={`cine974://personne/${resultat.aid}`} rel="noopener noreferrer">
	          <div className="Liste" key={resultat.aid}>
	          	<div className="col_1_3">
		          <LazyLoadImage
		            alt={resultat.nom}
		            height={'220'}
		            src={resultat.media_moyen} // use normal <img> attributes as props
		            width={'165'} 
		            className="lazy-load-image" />
		          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
		        </div>
		        <div className="col_2_3">
		          <h2 className="pbottom8 m">{resultat.nom}</h2>
		          <p>alias {resultat.role} dans le {resultat.type} <a href={`cine974://${resultat.type}/${resultat.item_id}`}>{resultat.titre}</a></p>
		        </div>
	          </div>
	         </a>

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}
	    </div>
	  );
	}

	 return (
    <div className="App">
      
        <div>
         {renderContent()}
        </div>
      
    </div>
  );
	
}

export default ResultatsRoles;
