import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './Routes'; // ou './routes/Routes' si vous l'avez placé dans un sous-dossier
import './styles/index.css';
import reportWebVitals from './reportWebVitals';
import ReactGA from 'react-ga';

// Initialize google analytics
ReactGA.initialize('G-Z7JXS2BRP3');
ReactGA.pageview(window.location.pathname + window.location.search);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
