import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function PersonneSexy() {
	const [data, setData] = useState({});
  	const [loading, setLoading] = useState(true);
  	const { id, page } = useParams(); // Récupérer le paramètre id depuis l'URL

  	App();

  	
  	useEffect(() => {
	    // Charger les données depuis le fichier PHP
  		fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?x=${page}&type=personneSexy&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
  		.then((response) => response.json())
  		.then((jsonData) => {
  			setData(jsonData);
  			setLoading(false);
  		})
  		.catch((error) => {
  			console.error('Erreur de chargement:', error);
  			setLoading(false);
  		});
	  }, [id, page]); // Mettre à jour lorsque id change


  



  let typepersonne;
	if (parseInt(id, 10) === 2) {
		typepersonne = "Actrices";
	} else {
		typepersonne = "Acteurs";
	}
  
  
  console.log("DATAS:", data);

	let personnes // personnes
	if (data.personnes) {
		personnes = data.personnes;
	} else {
		personnes = [];
	}



	console.log(personnes);

	const renderContent = () => {
	  return (
	    <div className="Personnes">

	      <Menu />
	      
	      <h1>{typepersonne} Sexy</h1>
	      <p className="Liste"><a href="cine974://cine+++/10000002" className="btn-back">← retour</a></p>
	      
	      {personnes ? (
	        personnes.map((resultat, index) => (
	         
	          <div className="Liste" key={index}>
	          	<div className="col_1_3">
	          	  <a href={`cine974://personne/${resultat.aid}`} rel="noopener noreferrer" className="noborder">
			          <LazyLoadImage
			            alt={resultat.nom}
			            height={'220'}
			            src={resultat.media_moyen} // use normal <img> attributes as props
			            width={'165'} 
			            className="lazy-load-image"
			             />
			          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
			      </a>
		        </div>
		        <div className="col_2_3">
		          <a href={`cine974://personne/${resultat.aid}`} rel="noopener noreferrer"><h2 className="pbottom0 m">{resultat.nom}</h2></a>
		          <p className="nomargintop">{resultat.nbfilms} films, {resultat.nbseries} series</p>
		          <p className="nomargintop"><span className="actuInfos">🏆 {resultat.classement}</span><br/><span>★ {resultat.note} ({resultat.nbnote})</span></p>
		        </div>
	          </div>
	         

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}

	    
	      <div className="pagination"><ul>
	      	{Array.from({ length: data.nb_pages }, (_, i) => i + 1).map((number) => (
		        <li key={number} className={number === parseInt(page, 10) ? 'current' : ''}>
		         	<a href={`https://app.cine974.com/personne/sexy/${id}/${number}?time=${new Date().getTime()}`}>
		          	{`${number}`}
		          </a>
		        </li>
		      ))}
	      </ul></div>
	      

	    </div>


	  );
	}

	return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
 
  );


}


export default PersonneSexy;