import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Personnes from './components/Personnes';
import PersonneSexy from './components/PersonneSexy';
import PersonneALaffiche from './components/PersonneALaffiche';
import Personne from './components/Personne';
import Voxographie from './components/Voxographie';
import Repliques from './components/Repliques';
import SortiesMemeJour from './components/SortiesMemeJour';
import Streaming from './components/Streaming';
import BandesOriginales from './components/BandesOriginales';
import BoxOffice from './components/BoxOffice';
import TopFilms from './components/TopFilms';
import TopFilmsAnnee from './components/TopFilmsAnnee';
import Tv from './components/Tv';
import Role from './components/Role';
//import App from './components/App';
import Actu from './components/Actu';
import Home from './components/Home';
import Series from './components/Series';
import Serie from './components/Serie';
import ResultatsSeries from './components/ResultatsSeries';
import ResultatsPersonnes from './components/ResultatsPersonnes';
import ResultatsRoles from './components/ResultatsRoles';
import CineMarmay from './components/CineMarmay';
import Evenements from './components/Evenements';

function AppRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/serie" element={<Series />} />
        <Route exact path="/serie/:id" element={<Serie />} />
        <Route exact path="/personne" element={<Personnes />} />
        <Route path="/personne/:id" element={<Personne />} />
        <Route path="/personne/sexy/:id/:page" element={<PersonneSexy />} />
        <Route path="/personne/a_laffiche/:id" element={<PersonneALaffiche />} />
        <Route path="/actu/:id" element={<Actu />} />
        <Route path="/serie/resultats" element={<ResultatsSeries />} />
        <Route path="/personne/resultats" element={<ResultatsPersonnes />} />
        <Route path="/role/resultats" element={<ResultatsRoles />} />
        <Route exact path="/voxographie" element={<Voxographie />} />
        <Route path="/repliques/:id" element={<Repliques />} />
        <Route path="/sorties-jour/:id" element={<SortiesMemeJour />} />
        <Route path="/streaming/:id" element={<Streaming />} />
        <Route path="/bo/:id" element={<BandesOriginales />} />
        <Route path="/boxoffice/:id" element={<BoxOffice />} />
        <Route exact path="/topfilms" element={<TopFilms />} />
        <Route path="/topfilmsannee/:id" element={<TopFilmsAnnee />} />
        <Route exact path="/tv" element={<Tv />} />
        <Route exact path="/role" element={<Role />} />
        <Route exact path="/cine-marmay" element={<CineMarmay />} />
        <Route exact path="/evenement" element={<Evenements />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
