import React, { useState, useEffect } from 'react';
//import { useNavigate } from 'react-router-dom';
//import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function Evenements() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  //const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();

  

  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=evenement&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, []); // Mettre à jour lorsque id change


 

  console.log("DATAS:", data);


  
  let mois1 // mois1 = mois M0
  if (data.mois1) {
    mois1 = data.mois1;
  } else {
    mois1 = [];
  }

  let mois2 // mois2 = mois M+1
  if (data.mois2) {
    mois2 = data.mois2;
  } else {
    mois2 = [];
  }

  let mois3 // mois3 = mois M+2
  if (data.mois3) {
    mois3 = data.mois3;
  } else {
    mois3 = [];
  }

  let mois4 // mois4 = mois M+3
  if (data.mois4) {
    mois4 = data.mois4;
  } else {
    mois4 = [];
  }

  
  const GUTTER_SIZE = 20;

  
  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.actu_id}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.actu_id}`} className="lazy-load-image" width="260" height="130" />
          <div className="tagsE">
            {image.tags && image.tags.map((tag, i) => (
              <div key={i} className={tag.tag_style}>
                {tag.tag_name}
              </div>
            ))}
          </div>
          <h3 className="st cutted">{image.titre}<br/></h3>
          <h4 className="st">{image.datepub}<br/>{image.lieu}</h4>
        </a>
      </div>
    );
  };

  

  const renderContent = () => {
    
      return <div className="Series">

        <Menu />

        
       
        <h1>Évènements</h1>
        <p>Agenda des évènements à La Reunion : cinéma, spectacles, théâtre, humour, concerts...</p>
        
       
        
       
        <h2 className="st">{data.mois1_nom}</h2>
        {mois1 && mois1.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={mois1.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={mois1} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun évènement.</div>
        )}

        <h2 className="st">{data.mois2_nom}</h2>
        {mois2 && mois2.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={mois2.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={mois2} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun évènement.</div>
        )}

        <h2 className="st">{data.mois3_nom}</h2>
        {mois3 && mois3.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={mois3.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={mois3} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun évènement.</div>
        )}

        <h2 className="st">{data.mois4_nom}</h2>
        {mois4 && mois4.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={mois4.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={mois4} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun évènement.</div>
        )}

        




      </div>;
    
  };
  
  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Evenements;
