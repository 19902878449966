import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
//import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import App from './App';

function Actu() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();
  
  console.log("ID:", id);
 
  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=actu&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, [id]); // Mettre à jour lorsque id change


 


  console.log("DATAS:", data);

  const { films } = data; // Récupérer le champ films de l'objet data
  const { series } = data; // Récupérer le champ series de l'objet data
  const { personnes } = data; // Récupérer le champ personnes de l'objet data
  const { news } = data; // Récupérer le champ news de l'objet data
 
 

  /*const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    lazyLoad: true,
    centerMode: false,
    slidesToShow: 1.2,
    swipeToSlide: true,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 2.2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2.2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1.2
          }
        }
    ]
  };*/

  const GUTTER_SIZE = 20;

  const RowFilm = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://film/${image.film_id}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.film_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st"><em>{image.infosupp}</em></h4>
        </a>
      </div>
    );
  };

  const RowSerie = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`https://app.cine974.com/serie/${image.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.serie_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st"><em>{image.infosupp}</em></h4>
        </a>
      </div>
    );
  };

  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.article_id}`} rel="noopener noreferrer">
          <img src={image.illustration} alt={`${image.article_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.article_titre}</h3>
          <h4 className="st">{image.article_date}</h4>
        </a>
      </div>
    );
  };

  const RowPersonne = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://personne/${image.aid}`} rel="noopener noreferrer">
          <img src={image.media_moyen} alt={`${image.aid}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st text-center">{image.nom}</h3>
        </a>
      </div>
    );
  };

  

  const { quiz } = data;

  const iframeSrc = data.quiz;
  const iframeHeight = 1000;
  const iframeHeight400 = 300;

  
  // Fonction pour analyser le contenu HTML et extraire les iframes
  const extractIframes = (htmlContent) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const iframeElements = doc.querySelectorAll('iframe');

    return Array.from(iframeElements).map((iframe, index) => (
      <iframe
        key={index}
        src={iframe.src}
        height={iframeHeight400} 
        title={index} 
        className="iframe-responsive" 
        frameBorder="0"
        allowFullScreen 
      ></iframe>
    ));
  };
  


  const renderContent = () => {

      const iframeComponents = extractIframes(data.contenu);
    
      return <div className="Actu">
        <LazyLoadImage
          alt={data.titre}
          height={'390'}
          src={data.illustration} // use normal <img> attributes as props
          width={'780'} 
          className="lazy-load-image" />
        {/*<img src={data.illustration} alt={data.titre} className='lmj-logo' />*/}
        <h1>{data.titre}</h1>
        <p><span className="actuInfos">📅 {data.date_pub}</span> <span className="actuInfos">✍️ {data.auteur}</span></p>
        <h2>{data.accroche}</h2>
        
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.contenu) }} />


        <div>
        {iframeComponents}
        </div>

        {quiz && quiz >0 ? (
          <div className="iframe-container">
            <iframe 
              src={`https://www.cine974.com/quiz/quiz${iframeSrc}/index.html`} 
              title="Quiz"
              className="iframe-responsive"
              height={iframeHeight}
            >
            </iframe>
          </div>
          ) : (
          <p className="noData"></p>
        )}
        

        
        {films && films.length > 0 ? (
        <>
        <h2 className="st">Cet article parle de ces films</h2>
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={films.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={films} // Données à passer à chaque élément
          >
            {RowFilm}
          </List>
        </>
          ) : (
          <div className="noData"></div>
        )}


        
        {series && series.length > 0 ? (
        <>
        <h2 className="st">Cet article parle de ces séries</h2>
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series} // Données à passer à chaque élément
          >
            {RowSerie}
          </List>
        </>
          ) : (
          <div className="noData"></div>
        )}

        
        {personnes && personnes.length > 0 ? (
        <>
        <h2 className="st">Cet article parle de ces personnes</h2>
          <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={personnes.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={personnes} // Données à passer à chaque élément
          >
            {RowPersonne}
          </List>
        </>
          ) : (
          <div className="noData"></div>
        )}

        <h2 className="st">A lire également</h2>
        {news && news.length > 0 ? (
          <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={news.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={news} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun article.</div>
        )}


      </div>;
    
  };

  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Actu;
