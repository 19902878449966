import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function BoxOffice() {
	const [data, setData] = useState({});
  	const [loading, setLoading] = useState(true);
  	const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  	App();
  

  	console.log("ID:", id);

  	useEffect(() => {
	    // Charger les données depuis le fichier PHP
  		fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=boxoffice&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
  		.then((response) => response.json())
  		.then((jsonData) => {
  			setData(jsonData);
  			setLoading(false);
  		})
  		.catch((error) => {
  			console.error('Erreur de chargement:', error);
  			setLoading(false);
  		});
	  }, [id]); // Mettre à jour lorsque id change


  	

  	const annees = [];
	  const anneeCourante = new Date().getFullYear(); // Obtient l'année en cours

	  // Remplir le tableau avec des années de l'année en cours à 2016
	  for (let i = anneeCourante; i >= 2016; i--) {
	    annees.push(i);
	  }


  
  	console.log("DATAS:", data);

	let boxoffice // boxoffice
	if (data.boxoffice) {
		boxoffice = data.boxoffice;
	} else {
		boxoffice = [];
	}

	let message_annee_en_cours;
	if (parseInt(id, 10) === anneeCourante) {
		message_annee_en_cours = "⚠️ Les données de l'année en cours ne sont qu'à titre informatif. Il faut attendre généralement février de l'année suivante pour avoir les données définitives.";
	} else {
		message_annee_en_cours = "";
	}

  


	console.log(boxoffice);

	const renderContent = () => {
	  return (
	    <div className="Boxoffice">

	      <Menu />
	      
	      <h1>Box-Office {id}</h1>
	      <p>Box-Office de films par nombre d'entrées en France</p>
	      <p><em>{message_annee_en_cours}</em></p>

	      {boxoffice ? (
	        boxoffice.map((resultat, index) => (
	         
	          <div className="Liste" key={index}>
	          	<div className="col_1_3">
	          	  <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer" className="noborder">
			          <LazyLoadImage
			            alt={resultat.titre}
			            height={'220'}
			            src={resultat.media_url} // use normal <img> attributes as props
			            width={'165'} 
			            className="lazy-load-image"
			             />
			          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
			      </a>
		        </div>
		        <div className="col_2_3">
		          <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer"><h2 className="pbottom0 m">{resultat.titre}</h2></a>
		          

		          <p className="nomargintop nomarginbottom"><em>de</em> 
			          {resultat.realisateurs.map((item, index) => (
		          	  <>
		              	<a className="linkListe3 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.realisateurs.length - 1 && ','}
		              </>
		            ))}
			        </p>

			        <p className="nomargintop"><em>avec</em> 
			          {resultat.casting.map((item, index) => (
		          	  <>
		              	<a className="linkListe3 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.casting.length - 1 && ','}
		              </>
		            ))} ...
			        </p>
			         
		          <p className="nomargintop"><span className="actuInfos">🏆 {index+1}</span><br/><span className="actuInfos2">🎟️ {resultat.boxoffice_fr}</span></p>
		        </div>
	          </div>
	         

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}

	      <div className="pagination"><ul>
	      	{annees.map((annee, index) => (
	          <li key={index} className={annee === parseInt(id, 10) ? 'current' : ''}><a href={`https://app.cine974.com/boxoffice/${annee}?time=${new Date().getTime()}`}>{annee}</a></li>
	        ))}
	      </ul></div>



	      

	    </div>


	  );
	}

	return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
 
  );


}


export default BoxOffice;