import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import DOMPurify from 'dompurify';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Slider from "react-slick";
import { FixedSizeList as List } from 'react-window';
import { RotatingLines } from 'react-loader-spinner';
import App from './App';

function Personne() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  App();

  console.log("ID:", id);

  
 
  useEffect(() => {
    // Charger les données depuis le fichier PHP
    fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=personne&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
      .then((response) => response.json())
      .then((jsonData) => {
        setData(jsonData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erreur de chargement:', error);
        setLoading(false);
      });
  }, [id]); // Mettre à jour lorsque id change


  


  console.log("DATAS:", data);

  //const { films } = data; // Récupérer le champ films de l'objet data
 
  let films_avec; // films_avec = acteurs
  if (data.films_avec) {
    films_avec = data.films_avec;
  } else {
    films_avec = [];
  }

  let films_de; // films_de = réalisateur
  if (data.films_de) {
    films_de = data.films_de;
  } else {
    films_de = [];
  }

  let series_avec; // films_avec = acteurs
  if (data.series_avec) {
    series_avec = data.series_avec;
  } else {
    series_avec = [];
  }

  let actus_de; // films_de = réalisateur
  if (data.actus_de) {
    actus_de = data.actus_de;
  } else {
    actus_de = [];
  }

  let films_aff; // films_aff = films à l'affiche de l'acteur
  if (data.films_aff) {
    films_aff = data.films_aff;
  } else {
    films_aff = [];
  }

  let voix_fr; // voix_fr = voix française de l'acteur
  if (data.voix_fr) {
    voix_fr = data.voix_fr;
  } else {
    voix_fr = [];
  }

  let voix_de // voix_de = est la voix fr de ... 
  if (data.voix_de) {
    voix_de = data.voix_de;
  } else {
    voix_de = [];
  }

  let galerie // galerie = photos de ... 
  if (data.galerie) {
    galerie = data.galerie;
  } else {
    galerie = [];
  }
 

  /*const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };

  const settings2 = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };*/

  const settings3 = {
    className: "galery",
      dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1.2,
    lazyLoad: true,
    centerMode: false,
    swipeToSlide: true
  };

  const GUTTER_SIZE = 20;

  const RowFilm = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://film/${image.film_id}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.film_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st">{image.titre} <div className="dateannee">({image.infosupp})</div></h3>
        </a>
        <h4 className="st"><em>{image.role}</em></h4>
      </div>
    );
  };

  const RowSerie = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://serie/${image.serie_id}?time=${new Date().getTime()}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.serie_id}`} className="lazy-load-image" width="340" height="453" />
          <h3 className="st">{image.titre} <div className="dateannee">({image.infosupp})</div></h3>
        </a>
        <h4 className="st"><em>{image.role}</em></h4>
      </div>
    );
  };

  const RowActu = ({ index, style, data }) => {
    const image = data[index];
    return (
      <div style={{ ...style, width: style.width - GUTTER_SIZE }}>
        <a href={`cine974://actualite/${image.actu_id}`} rel="noopener noreferrer">
          <img src={image.affiche_moyen} alt={`${image.actu_id}`} className="lazy-load-image" width="260" height="130" />
          <h3 className="st">{image.titre}</h3>
          <h4 className="st">{image.infosupp}</h4>
        </a>
      </div>
    );
  };

 

  
  const renderContent = () => {
    
      return <div className="Personne">
        <div className="col_1_3">
          <LazyLoadImage
            alt={data.nom}
            height={'220'}
            src={data.media_moyen} // use normal <img> attributes as props
            width={'165'} 
            className="lazy-load-image" />
          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
        </div>
        <div className="col_2_3">
          <h1>{data.nom}</h1>
          <p>{data.infobasic}</p>
          <p>
          {data.is_sexy > 0 ? (
            <a href={`cine974://cine+++/10000002?time=${new Date().getTime()}`} className="actuInfos2">Sexy</a>
          ):(
            <span className="noData"></span>
          )}
           {data.is_top > 0 ? (
            <a href={`cine974://cine+++/10000002?time=${new Date().getTime()}`} className="actuInfos2">Top</a>
          ):(
            <span className="noData"></span>
          )}
          </p>
        </div>

        {films_aff && films_aff.length > 0 ? (
          <p className="personneInfos"> 🎬 {data.nom} est actuellement à l‘affiche de 
            {films_aff.map((texte, index) => (
              <a key={index} className="linkListe" href={`cine974://film/${texte.film_id}`} rel="noopener noreferrer">{texte.titre}</a> 
            ))}
          </p>
        ) : (
          <p className="noData"></p>
        )}

        {voix_fr && voix_fr.length > 0 ? (
          <p className="personneInfos"> 🗣️ Voix FR de {data.nom} 
            {voix_fr.map((texte, index) => (
              <a key={index} className="linkListe" href={`cine974://personne/${texte.aid}`} rel="noopener noreferrer">{texte.nom}</a> 
            ))}
          </p>
        ) : (
          <p className="noData"></p>
        )}

        {voix_de && voix_de.length > 0 ? (
          <p className="personneInfos"> 🗣️ {data.nom} est la voix FR de 
            {voix_de.map((texte, index) => (
              <a key={index} className="linkListe" href={`cine974://personne/${texte.aid}`} rel="noopener noreferrer">{texte.nom}</a> 
            ))}
          </p>
        ) : (
          <p className="noData"></p>
        )}

        <div className="personneInfos" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(data.presentation) }} />
        

        <h2 className="st">Films avec {data.nom}</h2>
        {films_avec && films_avec.length > 0 ? (
          <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={films_avec.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={films_avec} // Données à passer à chaque élément
          >
            {RowFilm}
          </List>
        ) : (
          <div className="aucuneinfo">Aucun film.</div>
        )}

        
        {films_de && films_de.length > 0 ? (
        <>
        <h2 className="st">Films de {data.nom}</h2>
          <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={films_de.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={films_de} // Données à passer à chaque élément
          >
            {RowFilm}
          </List>
        </>
          ) : (
          <div className="noData"></div>
        )}

        <h2 className="st">Actualités de {data.nom}</h2>
        {actus_de && actus_de.length > 0 ? (
        <List
            className="ListActu"
            height={200} // Hauteur de la liste
            itemCount={actus_de.length}
            itemSize={280} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={actus_de} // Données à passer à chaque élément
          >
            {RowActu}
          </List>
          ) : (
          <div className="aucuneinfo">Aucun article.</div>
        )}

        <h2 className="st">Séries avec {data.nom}</h2>
        {series_avec && series_avec.length > 0 ? (
        <List
            className="List"
            height={230} // Hauteur de la liste
            itemCount={series_avec.length}
            itemSize={150} // Largeur de chaque élément
            layout="horizontal" // Défilement horizontal
            width={1280} // Largeur de la liste
            itemData={series_avec} // Données à passer à chaque élément
          >
            {RowSerie}
          </List>
          ) : (
          <div className="aucuneinfo">Aucune série.</div>
        )}

        
        {galerie && galerie.length > 0 ? (
        <>
        <h2 className="st">Photos de {data.nom}</h2>
        <Slider {...settings3}>
          {galerie.map((image, index) => (
            <div className="galery-container" key={index}>
              <img src={image.photo} alt={`${data.nom}`} className="lazy-load-image" />
            </div>
          ))}
        </Slider>
        </>
          ) : (
          <div className="noData"></div>
        )}


      </div>
    
  };

  return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
  );
}

export default Personne;
