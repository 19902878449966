import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function Repliques() {
	const [data, setData] = useState({});
  	const [loading, setLoading] = useState(true);
  	const { id } = useParams(); // Récupérer le paramètre id depuis l'URL

  	App();

  	console.log("ID:", id);

  	useEffect(() => {
	    // Charger les données depuis le fichier PHP
  		fetch(`https://api.cine974.com/rest/v2/get/Webview/${id}?type=repliques&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
  		.then((response) => response.json())
  		.then((jsonData) => {
  			setData(jsonData);
  			setLoading(false);
  		})
  		.catch((error) => {
  			console.error('Erreur de chargement:', error);
  			setLoading(false);
  		});
	  }, [id]); // Mettre à jour lorsque id change


  


  	console.log("DATAS:", data);

	let repliques // voix
	if (data.repliques) {
		repliques = data.repliques;
	} else {
		repliques = [];
	}

	console.log(repliques);

	const renderContent = () => {
	  return (
	    <div className="Repliques">

	      <Menu />
	      
	      <h1>Répliques Cultes</h1>
	      <p>Une liste de répliques cultes du cinéma à travers le monde.</p>

	      {repliques ? (
	        repliques.map((resultat, index) => (
	         
	          <div className="Liste" key={index}>
	          	<div className="col_1_3">
	          	  <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer" className="noborder">
			          <LazyLoadImage
			            alt={resultat.titre}
			            height={'220'}
			            src={resultat.media_url} // use normal <img> attributes as props
			            width={'165'} 
			            className="lazy-load-image"
			             />
			          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
			      </a>
		        </div>
		        <div className="col_2_3">
		          <a href={`cine974://film/${resultat.film_id}`} rel="noopener noreferrer"><h2 className="pbottom0 m">{resultat.titre}</h2></a>
		          
			        <p className="nomargintop"><em>avec</em> 
			          {resultat.casting.map((item, index) => (
		          	  <>
		              	<a className="linkListe3 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.casting.length - 1 && ','}
		              </>
		            ))} ...
			        </p>
			         
		          <p className="nomargintop">{resultat.replique}</p>
		        </div>
	          </div>
	         

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}

	      <div className="pagination"><ul>
	      	{Array.from({ length: data.nb_pages }, (_, i) => i + 1).map((number) => (
		        <li key={number} className={number === parseInt(id, 10) ? 'current' : ''}>
		         	<a href={`https://app.cine974.com/repliques/${number}?time=${new Date().getTime()}`}>
		          	{`${number}`}
		          </a>
		        </li>
		      ))}
	      </ul></div>

	    </div>
	  );
	}

	return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
 
  );


}


export default Repliques;