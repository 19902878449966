import React, { useState } from 'react';

const SearchBar = ({ onSearch }) => {
  const [term, setTerm] = useState('');

  const handleChange = (e) => {
    setTerm(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSearch(term);
  };

  return (
    <div className="searchbar">
      <form className="FormRecherche" onSubmit={handleSubmit}>
        <input 
          type="text" 
          placeholder="Rechercher..." 
          value={term}
          onChange={handleChange}
        />
        <button type="submit">🔍</button>
      </form>
    </div>
  );
};

export default SearchBar;