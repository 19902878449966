import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { RotatingLines } from 'react-loader-spinner';
import Menu from './Menu';
import App from './App';

function Voxographie() {
	const [data, setData] = useState({});
  	const [loading, setLoading] = useState(true);

  	App();


  	useEffect(() => {
	    // Charger les données depuis le fichier PHP
  		fetch(`https://api.cine974.com/rest/v2/get/Webview/0?type=voxographie&zone=974&key=974APPV2&token=8863285a5a071336295f3d572f48081de44acd3183799357afb245dc1b7be1b4`)
  		.then((response) => response.json())
  		.then((jsonData) => {
  			setData(jsonData);
  			setLoading(false);
  		})
  		.catch((error) => {
  			console.error('Erreur de chargement:', error);
  			setLoading(false);
  		});
	  }, []); // Mettre à jour lorsque id change


  


  	console.log("DATAS:", data);

	let voix // voix
	if (data.voix) {
		voix = data.voix;
	} else {
		voix = [];
	}

	console.log(voix);

	const renderContent = () => {
	  return (
	    <div className="Voxographie">

	      <Menu />
	      
	      <h1>Voxographie</h1>
	      <p>Qui sont les voix françaises de ces acteurs étrangers ?</p>

	      {voix ? (
	        voix.map((resultat) => (
	         
	          <div className="Liste" key={resultat.voix_id}>
	          	<div className="col_1_3">
	          	  <a href={`cine974://personne/${resultat.voix_id}`} rel="noopener noreferrer" className="noborder">
			          <LazyLoadImage
			            alt={resultat.voix_nom}
			            height={'220'}
			            src={resultat.media_moyen} // use normal <img> attributes as props
			            width={'165'} 
			            className="lazy-load-image"
			             />
			          {/*<img src={data.media_moyen} alt={data.nom} className='lmj-logo' />*/}
			      </a>
		        </div>
		        <div className="col_2_3">
		          <a href={`cine974://personne/${resultat.voix_id}`} rel="noopener noreferrer"><h2 className="pbottom8 m">{resultat.voix_nom}</h2></a>
		          <p className="nomargintop">{resultat.voix_nom} est la voix française de 
		          	{resultat.lesvoix.map((item, index) => (
		          	  <>
		              	<a className="linkListe2 noborder" key={item.aid} href={`cine974://personne/${item.aid}`}>{item.nom}</a> 
		              	{index !== resultat.lesvoix.length - 1 && ','}
		              </>
		            ))}
		          </p>

		          {resultat.exemple_film_id ? (
		          	<div className="voxblocs">
			          	<div className="col_1_6">
				          	<div className="voix_img_container">
				          		<LazyLoadImage
					            alt={resultat.exemple_img}
					            height={'220'}
					            src={resultat.exemple_img} // use normal <img> attributes as props
					            width={'165'} 
					            className="voix_img lazy-load-image" />
					        </div>
				        </div>
			        	<div className="col_5_6"> {resultat.exemple_txt} <a href={`cine974://film/${resultat.exemple_film_id}`}>{resultat.exemple_film_titre}</a></div>
			        </div>
		          ) : ( <span></span> ) }
		          
		        </div>
	          </div>
	         

	        ))
	      ) : (
	        <div className="aucuneinfo">Pas de résultats</div>
	      )}
	    </div>
	  );
	}

	return (
    <div className="App">
      {loading ? (
        <div className="center-loader">
          <RotatingLines   
            strokeColor="#F9D100"
            strokeWidth="5"
            animationDuration="1"
            width="40"
            visible={true}   
          />
        </div>
      ) : (
        <div>
         {renderContent()}
        </div>
      )}
    </div>
 
  );


}


export default Voxographie;